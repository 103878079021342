<template>
    <div>
        <div class="header_holder text-center ma">
            <div class="rounder_top mb-2">
                <div class="card_header ubuntu_b primer">
                    Moje košarice
                </div>
            </div>
        </div>
        <SavedCartCard
            v-for="cart in savedCarts" :key="cart.id" v-bind:cart="cart"
        ></SavedCartCard>
        <h2 class="mt-8 pt-8 text-center" v-if="savedCarts.length===0">
            Nemate sačuvanih košarica
        </h2>
    </div>
</template>
<script>
import SavedCartCard from './SavedCartCard.vue'
import { mapGetters } from 'vuex'
export default {
    data() {
        return {
        }
    },
    components: { SavedCartCard },
    computed: {
        ...mapGetters({
            savedCarts: 'cart/getSavedCarts',
            userCredentials: 'user/getCredentials',
        })
    },
    methods: {
    },
    created () {
        this.$store.dispatch('cart/getSavedCarts')
    },
    beforeCreate(){
        if(this.$store.getters['user/getCredentials'].kosarice == 0) this.$router.push({ name: 'not_auth' })
    }
}
</script>
<style scoped>
    .card_header{
        position:relative;
        height:47px;
        line-height:47px;
        text-align:center;
    }
    .back{
        position:absolute;
        width:26px;
        height:26px;
        left:13px;
        top:13px;
    }
</style>