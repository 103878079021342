<template>
    <v-container>
        <v-row dense>
            <v-col cols="12" class="mb-6">
                <kosariceComponent />
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import kosariceComponent from '../components/kosariceComponent.vue'
export default {
    data() {
        return {
        }
    },
    components: { kosariceComponent },
}
</script>
